import React from "react";
import { graphql } from "gatsby";

import { SEO } from "components";
import { ChallengeBody, ChallengeHero } from "views";

function ChallengePage({ data }) {
  const { challenge, settings } = data;
  const { settingsData } = settings;
  const { challengeData } = challenge;

  return (
    <>
      <SEO
        title={challengeData.title && challengeData.title.text}
        description={challengeData.goal_description}
        imageOpenGraph={settingsData.seo_image && settingsData.seo_image.url}
        imageTwitter={settingsData.seo_image && settingsData.seo_image.url}
        imageAlt={settingsData.seo_image && settingsData.seo_image.alt}
      />
      <ChallengeHero {...challengeData} />
      <ChallengeBody {...challengeData} />
    </>
  );
}

export const query = graphql`
  query ChallengeQuery($prismicId: ID!) {
    challenge: prismicCharityChallenge(prismicId: { eq: $prismicId }) {
      url
      uid
      challengeData: data {
        title {
          text
        }
        logo {
          fluid(maxWidth: 256) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        subtitle {
          text
        }
        body {
          text
          html
        }
        goal
        goal_progress
        goal_description
        goal_units
        buttons {
          label
          link {
            url
          }
        }
      }
    }
    settings: prismicSettings {
      settingsData: data {
        seo_image {
          url
          alt
        }
      }
    }
  }
`;

export default ChallengePage;
